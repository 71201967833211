import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import ProjectInDepth from './pages/ProjectInDepth';
import Contact from "./pages/Contact";
import NoPage from './pages/NoPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Custom.css';

function App() {
    useEffect(() => {
        // Add a class to the body element
        document.body.classList.add('body-override');
    }, []); // Run this effect only once when the component mounts

  return (
      // The app is placed in 'app-container' to create a custom 'body'
      <div className="app-container">
          <Router>
              <Routes>
                  <Route exact path="/" element={<Home/>}/>
                  <Route path="/portfolio" exact={true} element={<Portfolio/>}/>
                  <Route path="/projects/:projectId" element={<ProjectInDepth/>}/>
                  <Route path="/contact" exact={true} element={<Contact/>}/>
                  <Route path="*" element={<NoPage/>}/>
              </Routes>
          </Router>
      </div>
  );
}

export default App;
